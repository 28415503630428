var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"oIqhtVEtD2I5bdiw_3DiZ"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

// TODO init with dsn value from new sentry project
// Sentry.init({
//   dsn:
//     SENTRY_DSN ||
//     'https://25e6e56057d74ed4b8f8cf9ecc0ae7a8@o1253348.ingest.sentry.io/4505107789971456',
//   tracesSampleRate: 0,
//   environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
//   autoSessionTracking: false,
// });
